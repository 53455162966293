import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Img from "gatsby-image"
import styles from "./index.module.css"

const AppIconImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} className={styles.appIcon} />
}

const IndexPage = () => (
  <Layout>
    <SEO title="DevPal - App Sales Report for Apple Developers" />
    <div className={styles.intro}>
      <AppIconImage />

      <div>
        <h1 className={styles.headline}>DevPal</h1>
        <h2 className={styles.subTitle}>App Sales Report for Apple Developers</h2>
      </div>
    </div>

    <a className="appStoreLink" href="https://apps.apple.com/us/app/id1534709966" target={"_blank"}>
      <img src={require("../images/appstore.png")} height={44} />
    </a>


    <hr />

    <h2>Screenshots</h2>

    <div className={styles.previews}>
      <img src={require("../images/5_App.png")} />
      <img src={require("../images/5_Widget.png")} />
      <img src={require("../images/6_Welcome.png")} />
    </div>

    <article>
      <h3>How DevPal Works?</h3>
      <p>
        DevPal uses Apple's App Store Connect API, enabled by WWDC 2020, to automatically access your app sales reports.
        You can configure DevPal to automatically track your app sales by generating an API Key in App Store Connect and
        getting your Vendor Number in the Financial & Sales Report.
      </p>

      <h3>Privacy</h3>
      <p>
        All data are saved locally and in your iCloud account, we don't upload any data to our servers.
      </p>
    </article>
  </Layout>
)

export default IndexPage
